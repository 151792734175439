import React from "react";
import { Grid, Container, Card } from "@mui/material";
import { Scatter } from 'react-chartjs-2';
import Chart from "chart.js/auto";  // avoid category scale error


const dummy = [['Aa1', 109],
 ['Aa1', 110],
 ['Aa4', 41],
 ['Aa4', 42],
 ['Aa4', 54],
 ['Aa4', 55],
 ['Aa7', 115],
 ['Aa7', 116],
 ['Aa7', 117],
 ['Aa7', 118]];


const lineIndex = {
    'Aa1': 0,
    'Aa2': 1,
    'Aa3': 2,
    'Aa4': 3,
    'Aa5': 4,
    'Aa6': 5,
    'Aa7': 6,
    'Aa8': 7,
};


const Plots = () => {

    let myData = [];
    for (let i = 0; i < dummy.length; i++) {
        let x = dummy[i][1];
        let y = lineIndex[dummy[i][0]];
        myData.push({x: x, y: y});
    }

    const data = {
        datasets: [
          {
            data: myData,
            backgroundColor: "#0073E6",
            pointStyle: "rectRounded",
            pointRadius: 5,
            borderWidth: 0,
          },
        ],
      };
      
      const options = {
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
        },
        scales: {
          x: {
            type: 'linear',
            position: 'bottom',
            grid: {
              display: false,
            },
            min: 0,
            max: 120,
            ticks: {
              display: false,
            }
          },
          y: {
            reverse: true,
            grid: {
              display: false,
            },
            min: 0,
            max: 8,
          }
          
        },
      };

    return (
        <React.Fragment>
            <Grid container className="search-image"></Grid>
            <Container className="home-content">
                <Card>
                <h1>Plots</h1>
                <Scatter data={data} options={options} />;
                </Card>
            </Container>
        </React.Fragment>
    );
}

export default Plots;