import React, { useEffect, useState } from "react";
import { useHttpClient } from "../hooks/http-hook";

import TabletSelection from "../components/tablet_selection";
import ParallelText from "../components/parallel_text";
import ParallelCard from "../components/parallel_card";
import {
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Container,
    FormControlLabel,
    FormGroup,
    Grid,
    Switch,
    Typography
} from "@mui/material";

import TABLETS from "../components/tablets";
import SearchInput from "../components/search_input";

const ParallelSearch = () => {
    const [searching, setSearching] = useState(false);

    const [text, setText] = useState(null);
    const [tablets, setTablets] = useState([]);
    const [allTablets, setAllTablets] = useState(false);

    const [matches, setMatches] = useState({});
    const [size, setSize] = useState(0);
    const [searchText, setSearchText] = useState(null);

    const [option, setOption] = useState("full");
    const [exact, setExact] = useState(false);

    const [valid, setValid] = useState(false);

    const { sendRequest } = useHttpClient();

    const handleSubmit = async () => {
        setMatches({});
        setSearchText(null);
        setSearching(true);

        const url = new URL("https://api.rongoscript.org/parallel");
        // const url = new URL("http://localhost:8080/parallel");
        url.searchParams.append("text", text);
        url.searchParams.append("tablets", tablets);
        url.searchParams.append("option", option);
        url.searchParams.append("exact", exact);

        const responseData = await sendRequest(
            url.toString(),
            "GET",
            null,
            {
                "Content-Type": "application/json",
                //"api-key": process.env.REACT_APP_API_KEY
            }
        )

        setMatches(responseData.matches);
        setSize(responseData.size);
        setSearchText(responseData.text);
        setSearching(false);
    }

    const checkboxHandler = letter => {
        if (tablets.includes(letter)) {
            const new_tablets = tablets.filter(item => item !== letter);
            setTablets(new_tablets);
        } else {
            setTablets(prev => [...prev, letter]);
        }
    }

    const inputChangeHandler = event => {
        event.target.value = event.target.value.replace(/[^.\d ]/g, "");
        const inputValue = event.target.value.replace(/ +(?= )/g, "");
        setText(inputValue);
    }

    const handleOptionSwitch = event => {
        if (event.target.checked) {
            setOption("simple");
        } else {
            setOption("full");
        }
    }

    const handleExactSwitch = () => {
        setExact(!exact);
    }

    const validateSearch = () => {
        setValid(text && text.trim().split(" ").length > 1 && tablets.length);
    }

    useEffect(() => {
        validateSearch();
    }, [text, tablets]);

    const selectAll = () => {
        if (allTablets) {
            setTablets([]);
        } else {
            setTablets([...TABLETS]);
        }
        setAllTablets(!allTablets);
    }

    return (
        <React.Fragment>
            <Grid container className="search-image">
            </Grid>
            <Container className="home-content" maxWidth="md" sx={{ mt: 0, mb: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card sx={{ p: 2, pb: 0, mt: 2 }}>
                            <CardContent>
                                <Grid container
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        mb: 2
                                    }}
                                >
                                    <img src={require("../img/icons/intro.png")} className="intro-image" />
                                    <Typography
                                        sx={{ fontWeight: "bold" }}
                                        variant="h4"
                                    >
                                        &nbsp;Parallel
                                    </Typography>
                                    <Typography variant="h4">
                                        &nbsp;Passages
                                    </Typography>
                                </Grid>
                                <SearchInput
                                    placeholder={"Enter text"}
                                    onChange={inputChangeHandler}
                                />

                                <Grid container>
                                    <Grid item xs={12} sm={4}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Switch />}
                                                label="Search all texts"
                                                onChange={selectAll}
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Switch />}
                                                label="Simplified encoding"
                                                onChange={handleOptionSwitch}
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Switch />}
                                                label="Exact match"
                                                onChange={handleExactSwitch}
                                            />
                                        </FormGroup>
                                    </Grid>
                                </Grid>

                                {!allTablets &&
                                    <TabletSelection
                                        enabled={!allTablets}
                                        selectedTablets={tablets}
                                        tablets={TABLETS}
                                        handleClick={checkboxHandler}
                                    />
                                }

                                <Box textAlign="center" sx={{ mt: 2 }}>
                                    {(valid &&
                                        <Button
                                            variant="gradient"
                                            sx={{ minWidth: "25%" }}
                                            onClick={handleSubmit}
                                        >
                                            search
                                        </Button>) ||
                                        <Button disabled
                                            variant="contained"
                                            sx={{ minWidth: "25%" }}
                                            onClick={handleSubmit}
                                        >
                                            search
                                        </Button>
                                    }
                                </Box>

                            </CardContent>
                        </Card>
                    </Grid>

                    {searching &&
                        <Container>
                            <Grid container
                                xs={12}
                                sx={{ mt: 2, justifyContent: "center" }}
                            >
                                <CircularProgress />
                            </Grid>
                        </Container>
                    }

                    {searchText &&
                        <Grid item xs={12}>
                            <ParallelCard text={searchText} matches={size} />
                        </Grid>
                    }

                    {Object.keys(matches).length !== 0 && Object.keys(matches).map(item => (
                        <Grid item xs={12} sm={6}>
                            <ParallelText key={item} tablet={item} matches={matches[item]} />
                        </Grid>))
                    }

                </Grid>
            </Container>
        </React.Fragment>
    );
}

export default ParallelSearch;
