import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Container,
    FormControlLabel,
    FormGroup,
    Grid,
    Switch,
    Typography
} from "@mui/material";

import { useHttpClient } from "../hooks/http-hook";
import TABLETS from "../components/tablets";
import TabletSelection from "../components/tablet_selection";
import GlyphCard from "../components/glyph_card";
import GlyphChart from "../components/glyph_chart";
import GlyphList from "../components/glyph_list";
import SearchInput from "../components/search_input";

const GlyphSearch = props => {
    const [searching, setSearching] = useState(false);

    const [glyph, setGlyph] = useState(null);
    const [tablets, setTablets] = useState([]);
    const [allTablets, setAllTablets] = useState(false);

    const [option, setOption] = useState("full");

    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
            { data: [] }
        ]
    });

    const [allBigrams, setAllBigrams] = useState([]);
    const [allLikelihoods, setAllLikelihoods] = useState([]);

    const [bigramData, setBigramData] = useState([]);
    const [frequencyData, setFrequencyData] = useState([]);

    const [valid, setValid] = useState(false);

    const { sendRequest } = useHttpClient();

    const handleSubmit = async () => {
        setBigramData([]);
        setFrequencyData([]);
        setSearching(true);

        const url = new URL("https://api.rongoscript.org/glyph");
        // const url = new URL("http://localhost:8080/glyph");
        if (!props.tabletMode)
            url.searchParams.append("glyph", glyph);
        url.searchParams.append("tablets", tablets);
        url.searchParams.append("option", option);

        const responseData = await sendRequest(
            url.toString(),
            "GET",
            null,
            {
                "Content-Type": "application/json",
                //"api-key": process.env.REACT_APP_API_KEY
            }
        )
        const frequencies = responseData.frequency;
        const allLabels = Object.keys(frequencies).sort(function (a, b) { return (a - b) });
        const labels = allLabels.filter(function (x) { return frequencies[x][0] > 1 });
        const values = labels.map(item => frequencies[item][0]);
        setChartData({
            labels: labels,
            datasets: [
                {
                    label: "Glyph count",
                    data: values
                }
            ]
        });

        setAllBigrams([...responseData.bigrams.most_frequent]);
        const bigrams = responseData.bigrams.most_frequent.slice(0, 5);
        setBigramData(bigrams);
        setAllLikelihoods([...responseData.bigrams.highest_likelihood]);
        const topGlyphs = Object.keys(frequencies).sort(function (a, b) { return (frequencies[b][0] - frequencies[a][0]) });
        setFrequencyData(topGlyphs.map(glyph => [glyph, frequencies[glyph][1]]));
        setSearching(false);
    }

    const validateSearch = () => {
        setValid((glyph > 0 || props.tabletMode) && tablets.length);
    }

    useEffect(() => {
        validateSearch();
    }, [glyph, tablets]);

    const checkboxHandler = letter => {
        if (tablets.includes(letter)) {
            const new_tablets = tablets.filter(item => item !== letter);
            setTablets(new_tablets);
        } else {
            setTablets(prev => [...prev, letter]);
        }
    }

    const inputChangeHandler = event => {
        event.target.value = event.target.value.replace(/[^.\d]/g, "");
        if (event.target.value.length > 3) {
            event.target.value = event.target.value.slice(0, 3);
        }
        const inputValue = event.target.value.padStart(3, "0");
        setGlyph(inputValue);
    }

    const handleSelectAllSwitch = () => {
        if (allTablets) {
            setTablets([]);
        } else {
            setTablets([...TABLETS]);
        }
        setAllTablets(!allTablets);
    }

    const handleOptionSwitch = event => {
        if (event.target.checked) {
            setOption("simple");
        } else {
            setOption("full");
        }
    }

    return (
        <React.Fragment>
            <Grid container className="search-image"></Grid>
            <Container className="home-content" maxWidth="md" sx={{ mt: 0, mb: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>

                        <Card sx={{ p: 2, pb: 0, mt: 2 }}>
                            <CardContent>
                                <Grid container
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        mb: 2
                                    }}
                                >
                                    <img src={require("../img/icons/intro.png")} className="intro-image" />
                                    <Typography
                                        sx={{ fontWeight: "bold" }}
                                        variant="h4"
                                    >
                                        &nbsp;{props.tabletMode && `Text` || `Glyph`}
                                    </Typography>
                                    <Typography variant="h4">
                                        &nbsp;Statistics
                                    </Typography>
                                </Grid>

                                {!props.tabletMode &&
                                    <SearchInput
                                        placeholder={"Enter glyph"}
                                        onChange={inputChangeHandler}
                                    />
                                }

                                <Grid container>
                                    <Grid item xs={12} sm={6}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Switch />}
                                                label="Search all texts"
                                                onChange={handleSelectAllSwitch}
                                            />
                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Switch />}
                                                label="Simplified encoding"
                                                onChange={handleOptionSwitch}
                                            />
                                        </FormGroup>
                                    </Grid>
                                </Grid>

                                {!allTablets &&
                                    <TabletSelection
                                        enabled={!allTablets}
                                        selectedTablets={tablets}
                                        tablets={TABLETS}
                                        handleClick={checkboxHandler}
                                    />
                                }

                                <Box textAlign="center" sx={{ mt: 2 }}>
                                    {(valid &&
                                        <Button
                                            variant="gradient"
                                            sx={{ minWidth: "25%" }}
                                            onClick={handleSubmit}
                                        >
                                            search
                                        </Button>) ||
                                        <Button disabled
                                            variant="contained"
                                            sx={{ minWidth: "25%" }}
                                            onClick={handleSubmit}
                                        >
                                            search
                                        </Button>
                                    }
                                </Box>

                            </CardContent>
                        </Card>
                    </Grid>

                    {props.tabletMode && frequencyData[0] &&
                        <React.Fragment>
                            <Grid item xs={12} sm={6}>
                                <GlyphChart data={chartData} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <GlyphList
                                    title={"Glyph frequency"}
                                    data={frequencyData}
                                    key={frequencyData}
                                    bigrams={false}
                                />
                            </Grid>
                        </React.Fragment>
                    }

                    {searching &&
                        <Container>
                            <Grid container xs={12} sx={{ mt: 2, justifyContent: "center" }}>
                                <CircularProgress />
                            </Grid>
                        </Container>
                    }

                    {!props.tabletMode && frequencyData[0] &&
                        <Grid item xs={12}>
                            <GlyphCard data={frequencyData} />
                        </Grid>
                    }

                    {bigramData[0] &&
                        <React.Fragment>
                            <Grid item xs={12} sm={6}>
                                <GlyphList
                                    title="Bigram frequency"
                                    data={allBigrams}
                                    percent={true}
                                    key={allBigrams}
                                    bigrams={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <GlyphList
                                    title="Bigram likelihood"
                                    data={allLikelihoods}
                                    percent={false}
                                    key={allLikelihoods}
                                    bigrams={true}
                                />
                            </Grid>
                        </React.Fragment>
                    }
                </Grid>
            </Container>
        </React.Fragment>
    );
}

export default GlyphSearch;
